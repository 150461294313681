.dropdown-submenu-container {
    position: relative;
}
.dropdown-submenu-container a.dropdown-submenu::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 0.8em;
}
.dropdown-submenu-container .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 1px;
    margin-right: auto;
}
.dropdown-menu-right .dropdown-submenu-container .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: 1px;
}
